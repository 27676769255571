import React, { Component } from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { InputGroup } from '@paljs/ui/Input';
const isSSR = typeof window === 'undefined';
const CKEditor = !isSSR ? require('ckeditor4-react') : <div />;
//const ClassicEditor = !isSSR ? require('@ckeditor/ckeditor5-build-classic') : <div />;
import { Button } from '@paljs/ui/Button';
import styled, { css } from 'styled-components';
import Select from '@paljs/ui/Select';
import { Tabs, Tab } from '@paljs/ui/Tabs';
import Popover2 from '@paljs/ui/Popover';
import SweetAlert from 'react-bootstrap-sweetalert';
import { EvaIcon } from '@paljs/ui/Icon';
import PopUp from './popUp';
import firebase from 'gatsby-plugin-firebase';
import Tooltip from '@paljs/ui/Tooltip';

interface BoxProps {
  nested?: boolean;
  container?: boolean;
  row?: boolean;
  large?: boolean;
}

const Box = styled.div<BoxProps>`
  ${({ theme, nested, container, row, large }) => css`
    position: relative;
    box-sizing: border-box;
    min-height: 1rem;
    overflow: hidden;
    text-align: center;
    background: #cddaf3;
    padding: 0.75rem 0.25rem;
    border-radius: 0.25rem;
    ${large && 'height: 8rem;'};
    ${row && 'margin-bottom: 1rem  !important;'};
    ${container && 'padding: .5em;'};
    ${nested && `background-color: #cddaf3;`};
  `}
`;
const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const tab_statuses = [
  { label: 'Draft', value: 'draft' },
  { label: 'Completed', value: 'completed' },
];

const colButton = ['1/12', '2/12', '3/12', '4/12', '5/12', '6/12', '7/12', '8/12', '9/12', '10/12', '11/12', '12/12'];

class ReportTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabData: [],
      showConfirm: false,
      deleteIndex: {},
      tabIndex: '',
      popUpType: '',
      modalIndexes: {},
      popUpData: '',
      isImgLoader: false,
      popUpType: '',
    };
  }

  componentDidMount() {
    if (this.props.tabData) {
      this.setState({ tabData: this.props.tabData });
    } else {
      this.getTabs();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tabData !== this.props.tabData) {
      if (this.props.tabData == null) {
        this.getTabs();
      } else {
        this.setState({ tabData: this.props.tabData });
      }
    } else if (prevProps.selectedProd !== this.props.selectedProd) {
      this.getTabs();
    }
  }

  getTabs = () => {
    //set initial tab structure
    let tempArr = [];
    const { tabs } = this.props;
    for (let i = 0; i < tabs.length; i++) {
      tempArr.push({
        id: tabs[i].id,
        label: tabs[i].name,
        sections: [],
        status: { label: 'Draft', value: 'draft' },
        title: '',
        subtitle: 'Trading Strategy',
        description: '',
        entry: '',
        stop: '',
        target: '',
      });
    }
    this.setState({ tabData: tempArr, popUpType: '' });
  };

  handleChange = (field, index, data) => {
    let tempArr = [...this.state.tabData];
    tempArr[index][field] = data;
    this.setState({ tabData: tempArr });
  };

  handleAddSection = (tabIndex, sectionIndex) => {
    let tempArr = [...this.state.tabData];
    let secObj = { title: '', rows: [] };
    tempArr[tabIndex]['sections'].splice(sectionIndex, 0, secObj);
    this.setState({ tabData: tempArr });
  };

  handleSectionTitle = (tabIndex, sectionIndex, data) => {
    let tempArr = [...this.state.tabData];
    tempArr[tabIndex]['sections'][sectionIndex]['title'] = data;
    this.setState({ tabData: tempArr });
  };

  chooseColumnType = (tabIndex, sectionIndex, rowIndex, colIndex, type) => {
    let tempArr = [...this.state.tabData];
    let rowArr = tempArr[tabIndex]['sections'][sectionIndex]['rows'][rowIndex];

    if (type == 'image') {
      rowArr.columns[colIndex]['colType'] = 'image';
    } else {
      rowArr.columns[colIndex]['colType'] = 'editor';
    }

    let modalIndexes = { tabIndex, sectionIndex, rowIndex, colIndex };
    this.setState({ tabData: tempArr, popUpType: type, modalIndexes, popUpData: '' });
  };

  handleDelete = (type, tabIndex, sectionIndex = '', rowIndex = '', columnIndex = '', event = null) => {
    //show the confirm box and set the indexes of the element to delete
    event && event.stopPropagation();
    this.setState({ showConfirm: true, deleteIndex: { type, tabIndex, sectionIndex, rowIndex, columnIndex } });
  };

  confirmDelete = () => {
    //on confirm delete the element according to its position
    let tempArr = [...this.state.tabData];
    const { deleteIndex } = this.state;

    if (deleteIndex.type == 'section') {
      tempArr[deleteIndex.tabIndex]['sections'].splice(deleteIndex.sectionIndex, 1);
    } else if (deleteIndex.type == 'row') {
      tempArr[deleteIndex.tabIndex]['sections'][deleteIndex.sectionIndex]['rows'].splice(deleteIndex.rowIndex, 1);
    } else if (deleteIndex.type == 'column') {
      tempArr[deleteIndex.tabIndex]['sections'][deleteIndex.sectionIndex]['rows'][deleteIndex.rowIndex][
        'columns'
      ].splice(deleteIndex.columnIndex, 1);
    } else if (deleteIndex.type == 'switch') {
      tempArr[deleteIndex.tabIndex]['sections'][deleteIndex.sectionIndex]['rows'][deleteIndex.rowIndex]['columns'][
        deleteIndex.columnIndex
      ]['colType'] = 'blank';

      tempArr[deleteIndex.tabIndex]['sections'][deleteIndex.sectionIndex]['rows'][deleteIndex.rowIndex]['columns'][
        deleteIndex.columnIndex
      ]['colValue'] = '';
    }

    this.setState({ tabData: tempArr, showConfirm: false });
  };

  handleEditor = (data) => {
    //set the editor data in the specific column
    let tempArr = [...this.state.tabData];
    let tabIndex = this.state.modalIndexes.tabIndex;
    let rowIndex = this.state.modalIndexes.rowIndex;
    let sectionIndex = this.state.modalIndexes.sectionIndex;
    let colIndex = this.state.modalIndexes['colIndex'];

    tempArr[tabIndex]['sections'][sectionIndex]['rows'][rowIndex]['columns'][colIndex]['colValue'] = data;
    this.setState({ tabData: tempArr, popUpType: '' });
  };

  openPopUpData = (tabIndex, sectionIndex, rowIndex, colIndex, type) => {
    //open the popup and show data
    let popUpData = this.state.tabData[tabIndex]['sections'][sectionIndex]['rows'][rowIndex]['columns'][colIndex][
      'colValue'
    ];
    let modalIndexes = { tabIndex, sectionIndex, rowIndex, colIndex };

    this.setState({ popUpData, popUpType: type, modalIndexes });
  };

  uploadImage = (file) => {
    //upload the image in firebase against a specific index
    let { modalIndexes } = this.state;
    this.setState({ isImgLoader: true });
    let _self = this;
    let storageRef = firebase
      .storage()
      .ref('reports/')
      .child(this.props.docId)
      .child(
        `${this.props.docId}-${modalIndexes['tabIndex']}-${modalIndexes['sectionIndex']}-${modalIndexes['rowIndex']}-${modalIndexes['colIndex']}`,
      );

    let tempArr = [...this.state.tabData];
    let tempObj =
      tempArr[modalIndexes.tabIndex]['sections'][modalIndexes.sectionIndex]['rows'][modalIndexes.rowIndex]['columns'][
        modalIndexes.colIndex
      ];

    storageRef
      .put(file)
      .then((result) => {
        storageRef.getDownloadURL().then((result) => {
          tempObj['colValue'] = result;
          _self.setState({ sections: tempArr, popUpType: '', isImgLoader: false });
        });
      })
      .catch((e) => {
        _self.setState({ isImgLoader: false });
      });
  };

  addRowInSection = (tabIndex, sectionIndex, rowIndex) => {
    let tempArr = [...this.state.tabData];
    let colObj = { columns: [] };
    tempArr[tabIndex]['sections'][sectionIndex]['rows'].splice(rowIndex, 0, colObj);
    this.setState({ tabData: tempArr });
  };

  addColumnInRow = (tabIndex, sectionIndex, rowIndex, colSize) => {
    let tempArr = [...this.state.tabData];
    let rowArr = tempArr[tabIndex]['sections'][sectionIndex]['rows'][rowIndex];
    rowArr['columns'].push({ colSize, colType: 'blank', colValue: '' });
    this.setState({ tabData: tempArr });
  };

  render() {
    return (
      <>
        <Tabs activeIndex={0} fullWidth className="justify-content-start">
          {this.state.tabData.map((tab, i) => (
            <Tab
              title={tab.label}
              responsive
              key={i}
              badge={{ status: tab.status.value == 'draft' ? 'Danger' : 'Success', title: '', position: 'topEnd' }}
            >
              <div className="row">
                <div className="col-sm-8 pr-0">
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Title"
                      value={tab.title}
                      name="title"
                      onChange={(e) => this.handleChange('title', i, e.target.value)}
                    />
                  </Input>
                </div>
                <div className="col-sm-4">
                  <Select
                    fullwidth
                    placeholder="Select Status"
                    options={tab_statuses}
                    onChange={(values) => this.handleChange('status', i, values)}
                    value={tab.status}
                  />
                </div>
              </div>
              <Input fullWidth shape="SemiRound">
                <input
                  type="text"
                  placeholder="Enter Title"
                  value={tab.subtitle}
                  name="subtitle"
                  onChange={(e) => this.handleChange('subtitle', i, e.target.value)}
                />
              </Input>
              <br />
              {!isSSR && (
                <CKEditor
                  data={tab.description ? tab.description : ''}
                  type="classic"
                  config={{ allowedContent: true }}
                  onChange={(event) => {
                    this.handleChange('description', i, event.editor.getData());
                  }}
                />
              )}
              <br />
              <Row>
                <Col breakPoint={{ xs: true }}>
                  <Box row className="entry-box">
                    <h5>Entry</h5>
                    <Input fullWidth shape="SemiRound">
                      <input
                        type="number"
                        placeholder="0.0"
                        onChange={(e) => this.handleChange('entry', i, e.target.value)}
                        value={tab.entry}
                      />
                    </Input>
                  </Box>
                </Col>
                <Col breakPoint={{ xs: true }}>
                  <Box row className="entry-box">
                    <h5>Stop</h5>
                    <Input fullWidth shape="SemiRound">
                      <input
                        type="number"
                        placeholder="0.0"
                        onChange={(e) => this.handleChange('stop', i, e.target.value)}
                        value={tab.stop}
                      />
                    </Input>
                  </Box>
                </Col>
                <Col breakPoint={{ xs: true }}>
                  <Box row className="entry-box">
                    <h5>Target</h5>
                    <Input fullWidth shape="SemiRound">
                      <input
                        type="number"
                        placeholder="0.0"
                        onChange={(e) => this.handleChange('target', i, e.target.value)}
                        value={tab.target}
                      />
                    </Input>
                  </Box>
                </Col>
              </Row>
              <Row>
                {' '}
                <Col>
                  <div className="text-center">
                    <Button onClick={() => this.handleAddSection(i, 0)}>Add Section to start</Button>
                  </div>
                </Col>
              </Row>
              {tab.sections.length
                ? tab.sections.map((section, secIndex) => (
                    <div key={secIndex}>
                      <div className="remove-btn">
                        <span onClick={() => this.handleDelete('section', i, secIndex)}>
                          <EvaIcon name="close-circle-outline" /> Remove Section
                        </span>
                      </div>
                      <div className="report-box box-pane">
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="text"
                            placeholder="Enter Title"
                            value={section.title}
                            onChange={(e) => this.handleSectionTitle(i, secIndex, e.target.value)}
                            name="title"
                          />
                        </Input>
                        <div className="text-center">
                          <Button onClick={() => this.addRowInSection(i, secIndex, 0)} status="Info">
                            Add Row To Start
                          </Button>
                        </div>
                        {section.rows.map((row, rowIndex) => (
                          <div key={rowIndex}>
                            <Row className="section-divider">
                              <div className="blank-wrap">
                                <Popover2
                                  className="inline-block"
                                  trigger="hover"
                                  placement="top"
                                  overlay={
                                    <Card className="col-popover popover-card">
                                      <CardBody>
                                        {colButton.map((colBut, key) => (
                                          <>
                                            <button
                                              onClick={() => this.addColumnInRow(i, secIndex, rowIndex, key + 1)}
                                              key={key}
                                            >
                                              {colBut}
                                            </button>
                                            <br />
                                          </>
                                        ))}
                                      </CardBody>
                                    </Card>
                                  }
                                >
                                  <button className="basic-btn">
                                    <EvaIcon name="plus-circle-outline" /> Column
                                  </button>
                                </Popover2>
                                <div className="action-btn-wrap">
                                  <span
                                    className="basic-remove-btn"
                                    onClick={() => this.handleDelete('row', i, secIndex, rowIndex)}
                                  >
                                    <EvaIcon name="trash-outline" />
                                  </span>
                                </div>
                              </div>
                              {row.columns.map((column, colIndex) => (
                                <Col breakPoint={{ xs: column.colSize }} key={colIndex}>
                                  <>
                                    {column.colType == 'blank' && (
                                      <div className="grey-blank no-option">
                                        <div className="remove-col-wrap">
                                          <Tooltip trigger="hover" placement="right" content="delete">
                                            <span
                                              className="trash-wrap"
                                              onClick={(e) =>
                                                this.handleDelete('column', i, secIndex, rowIndex, colIndex, e)
                                              }
                                            >
                                              <EvaIcon name="trash-outline" />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <span className="col-size-show">
                                          <em>{column.colSize}</em>
                                        </span>
                                        <div className="column-type text-right">
                                          <Popover2
                                            className="inline-block"
                                            trigger="click"
                                            placement="top"
                                            overlay={
                                              <Card className="popover-card">
                                                <CardBody>
                                                  <Button
                                                    className="m-1"
                                                    onClick={() =>
                                                      this.chooseColumnType(i, secIndex, rowIndex, colIndex, 'image')
                                                    }
                                                  >
                                                    <EvaIcon name="image-outline" />
                                                  </Button>
                                                  <Button
                                                    className="m-1"
                                                    onClick={() =>
                                                      this.chooseColumnType(i, secIndex, rowIndex, colIndex, 'editor')
                                                    }
                                                  >
                                                    <EvaIcon name="text-outline" />
                                                  </Button>
                                                </CardBody>
                                              </Card>
                                            }
                                          >
                                            <Button>
                                              <EvaIcon name="plus-circle-outline" />
                                            </Button>
                                          </Popover2>
                                        </div>
                                      </div>
                                    )}
                                    {column.colType == 'image' && (
                                      <>
                                        <div
                                          className="grey-blank no-option"
                                          onClick={() => this.openPopUpData(i, secIndex, rowIndex, colIndex, 'image')}
                                        >
                                          <div className="remove-col-wrap">
                                            <Tooltip trigger="hover" placement="right" content="delete">
                                              <span
                                                className="trash-wrap"
                                                onClick={(e) =>
                                                  this.handleDelete('column', i, secIndex, rowIndex, colIndex, e)
                                                }
                                              >
                                                <EvaIcon name="trash-outline" />
                                              </span>
                                            </Tooltip>
                                            <Tooltip trigger="hover" placement="right" content="clear">
                                              <span
                                                className={column.colType == 'blank' ? 'd-none' : ''}
                                                onClick={(e) =>
                                                  this.handleDelete('switch', i, secIndex, rowIndex, colIndex, e)
                                                }
                                              >
                                                <EvaIcon name="refresh-outline" />
                                              </span>
                                            </Tooltip>
                                          </div>
                                          {column.colValue ? (
                                            <img src={column.colValue} width="150px" />
                                          ) : (
                                            <span>
                                              <EvaIcon name="image-outline" />
                                            </span>
                                          )}
                                          <span className="col-size-show">
                                            <em>{column.colSize}</em>
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {column.colType == 'editor' && (
                                      <>
                                        <div
                                          className={`grey-blank ${!column.colValue.length ? 'no-option' : ''}`}
                                          onClick={() => this.openPopUpData(i, secIndex, rowIndex, colIndex, 'editor')}
                                        >
                                          <div className="remove-col-wrap">
                                            <Tooltip trigger="hover" placement="right" content="delete">
                                              <span
                                                className="trash-wrap"
                                                onClick={(e) =>
                                                  this.handleDelete('column', i, secIndex, rowIndex, colIndex, e)
                                                }
                                              >
                                                <EvaIcon name="trash-outline" />
                                              </span>
                                            </Tooltip>
                                            <Tooltip trigger="hover" placement="right" content="clear">
                                              <span
                                                className={column.colType == 'blank' ? 'd-none' : ''}
                                                onClick={(e) =>
                                                  this.handleDelete('switch', i, secIndex, rowIndex, colIndex, e)
                                                }
                                              >
                                                <EvaIcon name="refresh-outline" />
                                              </span>
                                            </Tooltip>
                                          </div>
                                          <span className="col-size-show">
                                            <em>{column.colSize}</em>
                                          </span>
                                          {column.colValue.length ? (
                                            <div
                                              className="mt-3"
                                              dangerouslySetInnerHTML={{ __html: column.colValue }}
                                            />
                                          ) : (
                                            <Button>Editor</Button>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </>
                                </Col>
                              ))}
                            </Row>
                            <div className="text-center">
                              <Button
                                onClick={() => this.addRowInSection(i, secIndex, rowIndex + 1)}
                                status="Info"
                                className="mt-2"
                              >
                                Add Next Row
                              </Button>
                            </div>
                          </div>
                        ))}
                        <Row></Row>
                      </div>
                      <div className="text-center">
                        <Button onClick={() => this.handleAddSection(i, secIndex + 1)}>Add Next Section</Button>
                      </div>
                    </div>
                  ))
                : null}
            </Tab>
          ))}
        </Tabs>
        {this.state.showConfirm && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="info"
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={this.confirmDelete}
            onCancel={() => this.setState({ showConfirm: false })}
          />
        )}

        <PopUp
          isOpen={this.state.popUpType !== ''}
          close={() => this.setState({ popUpType: '' })}
          type={this.state.popUpType}
          saveEditor={this.handleEditor}
          data={this.state.popUpData}
          saveImg={this.uploadImage}
          isImgLoader={this.state.isImgLoader}
        />
      </>
    );
  }
}

export default React.memo(ReportTab);
